import React, { useEffect, useState } from 'react';
import DeviceList from '../../components/DeviceList/DeviceList';
import { useAxios } from '../../api/AxiosInstance';
import './Devices.css';

const Devices = () => {
  const [devices, setDevices] = useState([]);
  const axiosInstance = useAxios();

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await axiosInstance.get('/devices/');
        setDevices(response.data);
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    };
    fetchDevices();
  }, [axiosInstance]);

  return (
    <section className="container devices-page">
      <h2>Your Devices</h2>
      <DeviceList devices={devices} />
    </section>
  );
};

export default Devices;
