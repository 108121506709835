import React, { useState, useEffect } from 'react';
import { useAxios } from '../../api/AxiosInstance';
import { toast } from 'react-toastify';
import './DeviceCommandsPanel.css';

const DeviceCommandsPanel = () => {
  const [devices, setDevices] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState('');
  const [commandType, setCommandType] = useState(1);
  const [payload, setPayload] = useState('');
  const [isHex, setIsHex] = useState(false);
  
  const axiosInstance = useAxios();
  const v2Api = axiosInstance.getV2();

  useEffect(() => {
    const fetchDevices = async () => {
      try {
        const response = await axiosInstance.get('/devices/');
        setDevices(response.data);
      } catch (error) {
        console.error('Error fetching devices:', error);
        toast.error('Failed to fetch devices');
      }
    };
    fetchDevices();
  }, [axiosInstance]);

  const handleSendCommand = async () => {
    if (!selectedDevice) {
      toast.warning('Please select a device');
      return;
    }

    try {
      let finalPayload = payload;
      if (isHex) {
        const hexString = payload.replace(/\s/g, '');
        if (!/^[0-9A-Fa-f]*$/.test(hexString)) {
          toast.error('Invalid hex string');
          return;
        }
        finalPayload = hexString;
      }

      await v2Api.post(`/devices/${selectedDevice}/command`, {
        command_type: parseInt(commandType),
        payload: finalPayload
      });
      
      toast.success('Command sent successfully');
    } catch (error) {
      console.error('Error sending command:', error);
      toast.error(error.response?.data?.detail || 'Failed to send command');
    }
  };

  return (
    <article className="device-commands-panel">
      <h3>Device Commands</h3>
      <div className="command-form">
        <div className="form-group">
          <label htmlFor="device-select">Device:</label>
          <select 
            id="device-select"
            value={selectedDevice} 
            onChange={(e) => setSelectedDevice(e.target.value)}
          >
            <option value="">Select a device</option>
            {devices.map((device) => (
              <option key={device.device_name} value={device.device_name}>
                {device.device_display_name} ({device.device_type})
              </option>
            ))}
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="command-type">Command Type:</label>
          <input
            id="command-type"
            type="number"
            value={commandType}
            onChange={(e) => setCommandType(e.target.value)}
            min="0"
          />
        </div>

        <div className="form-group">
          <label htmlFor="payload">Payload:</label>
          <textarea
            id="payload"
            value={payload}
            onChange={(e) => setPayload(e.target.value)}
            placeholder={isHex ? "Enter hex values (e.g., 00 FF A4)" : "Enter string payload"}
          />
        </div>

        <div className="form-group">
          <label className="checkbox-label">
            <input
              type="checkbox"
              checked={isHex}
              onChange={(e) => setIsHex(e.target.checked)}
            />
            Hex Input
          </label>
        </div>

        <button onClick={handleSendCommand} className="send-button">
          Send Command
        </button>
      </div>
    </article>
  );
};

export default DeviceCommandsPanel; 