import { useAxios } from './AxiosInstance';

export const useFirmwareApi = () => {
    const axiosInstance = useAxios();
    const v2Api = axiosInstance.getV2();

    return {
        uploadFirmware: async (formData, versionInfo) => {
            const response = await v2Api.post('/firmware/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                params: {
                    hardware_type: versionInfo.hardware_type,
                    version_major: versionInfo.version_major,
                    version_minor: versionInfo.version_minor,
                    version_patch: versionInfo.version_patch,
                    version_build: versionInfo.version_build,
                    git_branch: versionInfo.git_branch,
                }
            });
            return response.data;
        },

        listFirmware: async (params = {}) => {
            const response = await v2Api.get('/firmware/list', { params });
            return response.data;
        },

        getLatestFirmware: async (hardwareType, gitBranch = null) => {
            const params = { hardware_type: hardwareType };
            if (gitBranch) {
                params.git_branch = gitBranch;
            }
            const response = await v2Api.get('/firmware/latest', { params });
            return response.data;
        },

        downloadFirmware: async (firmwareId) => {
            const response = await v2Api.get(`/firmware/download/${firmwareId}`, {
                responseType: 'blob'
            });
            return response.data;
        },

        deleteFirmware: async (firmwareId) => {
            await v2Api.delete(`/firmware/${firmwareId}`);
        },

        updateDeviceToLatestFirmware: async (deviceMac) => {
            const response = await v2Api.post(`/device/${deviceMac}/update-firmware`);
            return response.data;
        },

        updateDeviceToSpecificFirmware: async (deviceMac, firmwareId) => {
            const response = await v2Api.post(`/device/${deviceMac}/update-firmware/${firmwareId}`);
            return response.data;
        },

        getOtaStatus: async (deviceMac) => {
            const response = await v2Api.get(`/device/${deviceMac}/ota-status`);
            return response.data;
        }
    };
}; 