import React from 'react';
import './ControllerSettings.css';

const ControllerSettings = ({
  settings,
  onChange,
  readOnly = false
}) => {
  const handleChange = (field, value) => {
    if (onChange && !readOnly) {
      // Convert string values to appropriate types
      let processedValue;
      if (field.includes('enabled')) {
        processedValue = value === true || value === 'true';
      } else if (field === 'data_send_interval_minutes') {
        processedValue = parseInt(value, 10);
      } else if (field === 'espnow_binding_phrase') {
        processedValue = value;
      } else {
        // Handle all float values
        processedValue = parseFloat(value);
      }

      onChange({
        ...settings,
        [field]: processedValue
      });
    }
  };

  if (!settings) {
    return <div className="settings-loading">Loading controller settings...</div>;
  }

  return (
    <div className="controller-settings">
      <h3>Calibration Settings</h3>
      <div className="controller-settings-grid">
        <div className="setting-item">
          <label htmlFor="clo2_calibration_min">CLO2 Calibration (@4mA):</label>
          <input
            id="clo2_calibration_min"
            type="number"
            step="0.001"
            value={settings.clo2_calibration_min}
            onChange={(e) => handleChange('clo2_calibration_min', e.target.value)}
            disabled={readOnly}
          />
          <small>Sensor reading at 4mA</small>
        </div>

        <div className="setting-item">
          <label htmlFor="clo2_calibration_max">CLO2 Calibration (@20mA):</label>
          <input
            id="clo2_calibration_max"
            type="number"
            step="0.001"
            value={settings.clo2_calibration_max}
            onChange={(e) => handleChange('clo2_calibration_max', e.target.value)}
            disabled={readOnly}
          />
          <small>Sensor reading at 20mA</small>
        </div>

        <div className="setting-item">
          <label htmlFor="ph_calibration_min">pH Calibration (@4mA):</label>
          <input
            id="ph_calibration_min"
            type="number"
            step="0.001"
            value={settings.ph_calibration_min}
            onChange={(e) => handleChange('ph_calibration_min', e.target.value)}
            disabled={readOnly}
          />
          <small>Sensor reading at 4mA</small>
        </div>

        <div className="setting-item">
          <label htmlFor="ph_calibration_max">pH Calibration (@20mA):</label>
          <input
            id="ph_calibration_max"
            type="number"
            step="0.001"
            value={settings.ph_calibration_max}
            onChange={(e) => handleChange('ph_calibration_max', e.target.value)}
            disabled={readOnly}
          />
          <small>Sensor reading at 20mA</small>
        </div>
      </div>

      <h3>Control Settings</h3>
      <div className="controller-settings-grid">
        <div className="setting-item">
          <label htmlFor="data_send_interval_minutes">Data Send Interval (minutes):</label>
          <input
            id="data_send_interval_minutes"
            type="number"
            min="1"
            max="1440"
            value={settings.data_send_interval_minutes}
            onChange={(e) => handleChange('data_send_interval_minutes', e.target.value)}
            disabled={readOnly}
          />
          <small>How often data is sent to the gateway (1-1440 minutes)</small>
        </div>

        <div className="setting-item">
          <label htmlFor="espnow_binding_phrase">ESP-NOW Binding Phrase:</label>
          <input
            id="espnow_binding_phrase"
            type="text"
            maxLength="32"
            value={settings.espnow_binding_phrase || ''}
            onChange={(e) => handleChange('espnow_binding_phrase', e.target.value)}
            disabled={readOnly}
          />
          <small>Security phrase for ESP-NOW communication</small>
        </div>

        <div className="setting-item">
          <label htmlFor="ppm_to_pulse_multiplier">PPM to Pulse Multiplier:</label>
          <input
            id="ppm_to_pulse_multiplier"
            type="number"
            step="0.01"
            min="0"
            value={settings.ppm_to_pulse_multiplier}
            onChange={(e) => handleChange('ppm_to_pulse_multiplier', e.target.value)}
            disabled={readOnly}
          />
          <small>Multiplier for converting PPM to pulse duration</small>
        </div>

        <div className="setting-item">
          <label htmlFor="pulse_per_gallon">Pulse per Gallon:</label>
          <input
            id="pulse_per_gallon"
            type="number"
            step="0.01"
            min="0"
            value={settings.pulse_per_gallon}
            onChange={(e) => handleChange('pulse_per_gallon', e.target.value)}
            disabled={readOnly}
          />
          <small>Multiplier for converting PPM to pulse duration</small>
        </div>

        <div className="setting-item">
          <label htmlFor="ppm_setpoint">PPM Setpoint:</label>
          <input
            id="ppm_setpoint"
            type="number"
            step="0.01"
            min="0"
            max="10"
            value={settings.ppm_setpoint}
            onChange={(e) => handleChange('ppm_setpoint', e.target.value)}
            disabled={readOnly}
          />
          <small>Target PPM level (0-10 PPM)</small>
        </div>
      </div>

      <h3>Alarm Settings</h3>
      <div className="controller-settings-grid">
        <div className="setting-group">
          <h4>CLO2 High Alarm</h4>
          <div className="setting-item">
            <label htmlFor="alarm_clo2_high_threshold">CLO2 High Threshold (PPM):</label>
            <input
              id="alarm_clo2_high_threshold"
              type="number"
              step="0.01"
              min="0"
              max="10"
              value={settings.alarm_clo2_high_threshold}
              onChange={(e) => handleChange('alarm_clo2_high_threshold', e.target.value)}
              disabled={readOnly}
            />
            <small>PPM level that triggers high alarm (0-10 PPM)</small>
          </div>

          <div className="setting-item checkbox">
            <label htmlFor="alarm_clo2_high_enabled">
              <input
                id="alarm_clo2_high_enabled"
                type="checkbox"
                checked={settings.alarm_clo2_high_enabled}
                onChange={(e) => handleChange('alarm_clo2_high_enabled', e.target.checked)}
                disabled={readOnly}
              />
              Enable CLO2 High Alarm
            </label>
          </div>
        </div>

        <div className="setting-group">
          <h4>CLO2 Low Alarm</h4>
          <div className="setting-item">
            <label htmlFor="alarm_clo2_low_threshold">CLO2 Low Threshold (PPM):</label>
            <input
              id="alarm_clo2_low_threshold"
              type="number"
              step="0.01"
              min="0"
              max="10"
              value={settings.alarm_clo2_low_threshold}
              onChange={(e) => handleChange('alarm_clo2_low_threshold', e.target.value)}
              disabled={readOnly}
            />
            <small>PPM level that triggers low alarm (0-10 PPM)</small>
          </div>

          <div className="setting-item checkbox">
            <label htmlFor="alarm_clo2_low_enabled">
              <input
                id="alarm_clo2_low_enabled"
                type="checkbox"
                checked={settings.alarm_clo2_low_enabled}
                onChange={(e) => handleChange('alarm_clo2_low_enabled', e.target.checked)}
                disabled={readOnly}
              />
              Enable CLO2 Low Alarm
            </label>
          </div>
        </div>

        <div className="setting-group">
          <h4>pH High Alarm</h4>
          <div className="setting-item">
            <label htmlFor="alarm_ph_high_threshold">pH High Threshold:</label>
            <input
              id="alarm_ph_high_threshold"
              type="number"
              step="0.1"
              min="0"
              max="14"
              value={settings.alarm_ph_high_threshold}
              onChange={(e) => handleChange('alarm_ph_high_threshold', e.target.value)}
              disabled={readOnly}
            />
            <small>pH level that triggers high alarm (0-14 pH)</small>
          </div>

          <div className="setting-item checkbox">
            <label htmlFor="alarm_ph_high_enabled">
              <input
                id="alarm_ph_high_enabled"
                type="checkbox"
                checked={settings.alarm_ph_high_enabled}
                onChange={(e) => handleChange('alarm_ph_high_enabled', e.target.checked)}
                disabled={readOnly}
              />
              Enable pH High Alarm
            </label>
          </div>
        </div>

        <div className="setting-group">
          <h4>pH Low Alarm</h4>
          <div className="setting-item">
            <label htmlFor="alarm_ph_low_threshold">pH Low Threshold:</label>
            <input
              id="alarm_ph_low_threshold"
              type="number"
              step="0.1"
              min="0"
              max="14"
              value={settings.alarm_ph_low_threshold}
              onChange={(e) => handleChange('alarm_ph_low_threshold', e.target.value)}
              disabled={readOnly}
            />
            <small>pH level that triggers low alarm (0-14 pH)</small>
          </div>

          <div className="setting-item checkbox">
            <label htmlFor="alarm_ph_low_enabled">
              <input
                id="alarm_ph_low_enabled"
                type="checkbox"
                checked={settings.alarm_ph_low_enabled}
                onChange={(e) => handleChange('alarm_ph_low_enabled', e.target.checked)}
                disabled={readOnly}
              />
              Enable pH Low Alarm
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControllerSettings; 