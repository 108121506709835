import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FaTools } from 'react-icons/fa';
import { AuthContext } from '../../context/AuthContext';
import './SideBar.css';

const Sidebar = () => {
  const { user } = useContext(AuthContext);

  return (
    <div className="sidebar">
      <h3>Menu</h3>
      <ul className="sidebar-links">
        <li><Link to="/devices">Devices</Link></li>
        <li><Link to="/organizations">Organizations</Link></li>
        <li><Link to="/users">Users</Link></li>
        {user?.is_admin && (
          <li>
            <Link to="/admin-tools" className="nav-link">
              <FaTools />
              <span>Admin Tools</span>
            </Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
