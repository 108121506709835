import React, { useState, useEffect } from 'react';
import { useFirmwareApi } from '../../api/firmware';
import { toast } from 'react-toastify';

const HARDWARE_TYPES = ['GATEWAY_V1', 'PPM_CONTROLLER_V2'];

const FirmwareManager = () => {
    const firmwareApi = useFirmwareApi();
    const [firmwareList, setFirmwareList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedHardware, setSelectedHardware] = useState('');
    const [selectedBranch, setSelectedBranch] = useState('');
    const [uploadForm, setUploadForm] = useState({
        hardware_type: '',
        version_major: '',
        version_minor: '',
        version_patch: '',
        version_build: '',
        git_branch: '',
        firmware_file: null,
    });
    const [deleteConfirmation, setDeleteConfirmation] = useState({
        open: false,
        firmwareId: null,
        version: '',
    });

    // Get unique branches from firmware list for the selected hardware type
    const availableBranches = React.useMemo(() => {
        if (!selectedHardware) return [];
        const branches = new Set(
            firmwareList
                .filter(fw => fw.hardware_type === selectedHardware)
                .map(fw => fw.git_branch)
        );
        return Array.from(branches).sort();
    }, [firmwareList, selectedHardware]);

    // Get filtered and sorted firmware list
    const filteredFirmware = React.useMemo(() => {
        if (!selectedHardware || !selectedBranch) return [];
        return firmwareList
            .filter(fw => 
                fw.hardware_type === selectedHardware && 
                fw.git_branch === selectedBranch
            )
            .sort((a, b) => {
                // Sort by version components in order
                const versionComps = ['version_major', 'version_minor', 'version_patch', 'version_build'];
                for (const comp of versionComps) {
                    if (a[comp] !== b[comp]) {
                        return b[comp] - a[comp]; // Descending order
                    }
                }
                return 0;
            });
    }, [firmwareList, selectedHardware, selectedBranch]);

    const fetchFirmwareList = async () => {
        try {
            const data = await firmwareApi.listFirmware();
            setFirmwareList(data);
        } catch (error) {
            console.error('Error fetching firmware list:', error);
            toast.error('Failed to fetch firmware list');
        }
    };

    useEffect(() => {
        fetchFirmwareList();
    }, []);

    // Reset branch selection when hardware type changes
    useEffect(() => {
        setSelectedBranch('');
    }, [selectedHardware]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUploadForm(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        setUploadForm(prev => ({
            ...prev,
            firmware_file: e.target.files[0]
        }));
    };

    const handleUpload = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const formData = new FormData();
            formData.append('firmware_file', uploadForm.firmware_file);

            const versionInfo = {
                hardware_type: uploadForm.hardware_type,
                version_major: parseInt(uploadForm.version_major),
                version_minor: parseInt(uploadForm.version_minor),
                version_patch: parseInt(uploadForm.version_patch),
                version_build: parseInt(uploadForm.version_build),
                git_branch: uploadForm.git_branch,
            };

            await firmwareApi.uploadFirmware(formData, versionInfo);
            toast.success('Firmware uploaded successfully');
            // Reset form
            setUploadForm({
                hardware_type: '',
                version_major: '',
                version_minor: '',
                version_patch: '',
                version_build: '',
                git_branch: '',
                firmware_file: null,
            });
            // Refresh list
            fetchFirmwareList();
        } catch (error) {
            console.error('Error uploading firmware:', error);
            toast.error(error.response?.data?.detail || 'Failed to upload firmware');
        } finally {
            setLoading(false);
        }
    };

    const handleDownload = async (firmwareId) => {
        try {
            const blob = await firmwareApi.downloadFirmware(firmwareId);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `firmware_${firmwareId}.bin`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
            toast.success('Firmware download started');
        } catch (error) {
            console.error('Error downloading firmware:', error);
            toast.error('Failed to download firmware');
        }
    };

    const handleDeleteClick = (firmware) => {
        const versionString = `${firmware.version_major}.${firmware.version_minor}.${firmware.version_patch}`;
        setDeleteConfirmation({
            open: true,
            firmwareId: firmware.id,
            version: versionString,
        });
    };

    const handleDeleteConfirm = async () => {
        try {
            await firmwareApi.deleteFirmware(deleteConfirmation.firmwareId);
            toast.success('Firmware deleted successfully');
            fetchFirmwareList(); // Refresh the list
        } catch (error) {
            console.error('Error deleting firmware:', error);
            toast.error(error.response?.data?.detail || 'Failed to delete firmware');
        } finally {
            setDeleteConfirmation({ open: false, firmwareId: null, version: '' });
        }
    };

    const handleDeleteCancel = () => {
        setDeleteConfirmation({ open: false, firmwareId: null, version: '' });
    };

    return (
        <article className="firmware-manager">
            <h3>Firmware Management</h3>

            {/* Upload Form */}
            <article className="upload-section">
                <h4>Upload New Firmware</h4>
                <form onSubmit={handleUpload} className="grid">
                    <div className="form-group">
                        <label htmlFor="hardware-type">Hardware Type</label>
                        <select
                            id="hardware-type"
                            name="hardware_type"
                            value={uploadForm.hardware_type}
                            onChange={handleInputChange}
                            required
                        >
                            <option value="">Select Hardware Type</option>
                            {HARDWARE_TYPES.map(type => (
                                <option key={type} value={type}>{type}</option>
                            ))}
                        </select>
                    </div>

                    <div className="version-grid">
                        <div className="form-group">
                            <label htmlFor="version-major">Major Version</label>
                            <input
                                id="version-major"
                                name="version_major"
                                type="number"
                                value={uploadForm.version_major}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="version-minor">Minor Version</label>
                            <input
                                id="version-minor"
                                name="version_minor"
                                type="number"
                                value={uploadForm.version_minor}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="version-patch">Patch Version</label>
                            <input
                                id="version-patch"
                                name="version_patch"
                                type="number"
                                value={uploadForm.version_patch}
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="version-build">Build Number</label>
                            <input
                                id="version-build"
                                name="version_build"
                                type="number"
                                value={uploadForm.version_build}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="git-branch">Git Branch</label>
                        <input
                            id="git-branch"
                            name="git_branch"
                            type="text"
                            value={uploadForm.git_branch}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="firmware-file">Firmware File</label>
                        <input
                            id="firmware-file"
                            type="file"
                            accept=".bin"
                            onChange={handleFileChange}
                        />
                    </div>

                    <button 
                        type="submit" 
                        className="primary" 
                        disabled={loading || !uploadForm.firmware_file}
                    >
                        {loading ? 'Uploading...' : 'Upload Firmware'}
                    </button>
                </form>
            </article>

            {/* Firmware List */}
            <article className="firmware-list">
                <h4>Available Firmware</h4>
                
                {/* Selection Controls */}
                <div className="grid">
                    <div className="form-group">
                        <label htmlFor="hardware-filter">Hardware Type</label>
                        <select
                            id="hardware-filter"
                            value={selectedHardware}
                            onChange={(e) => setSelectedHardware(e.target.value)}
                        >
                            <option value="">Select Hardware Type</option>
                            {HARDWARE_TYPES.map(type => (
                                <option key={type} value={type}>{type}</option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="branch-filter">Git Branch</label>
                        <select
                            id="branch-filter"
                            value={selectedBranch}
                            onChange={(e) => setSelectedBranch(e.target.value)}
                            disabled={!selectedHardware}
                        >
                            <option value="">Select Branch</option>
                            {availableBranches.map(branch => (
                                <option key={branch} value={branch}>{branch}</option>
                            ))}
                        </select>
                    </div>
                </div>

                {/* Version List */}
                {selectedHardware && selectedBranch && (
                    <div className="overflow-auto">
                        <table>
                            <thead>
                                <tr>
                                    <th>Version</th>
                                    <th>Upload Date</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredFirmware.map((firmware) => {
                                    const hasMultipleBuilds = filteredFirmware.some(fw => 
                                        fw.id !== firmware.id &&
                                        fw.version_major === firmware.version_major &&
                                        fw.version_minor === firmware.version_minor &&
                                        fw.version_patch === firmware.version_patch
                                    );

                                    const versionString = `${firmware.version_major}.${firmware.version_minor}.${firmware.version_patch}${hasMultipleBuilds ? `(${firmware.version_build})` : ''}`;

                                    return (
                                        <tr key={firmware.id}>
                                            <td>{versionString}</td>
                                            <td>
                                                {new Date(firmware.created_at).toLocaleString()}
                                            </td>
                                            <td>
                                                <div className="button-group">
                                                    <button
                                                        className="outline"
                                                        onClick={() => handleDownload(firmware.id)}
                                                        title="Download"
                                                    >
                                                        ⬇️
                                                    </button>
                                                    <button
                                                        className="outline error"
                                                        onClick={() => handleDeleteClick(firmware)}
                                                        title="Delete"
                                                    >
                                                        🗑️
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                )}
            </article>

            {/* Delete Confirmation Dialog */}
            {deleteConfirmation.open && (
                <dialog open>
                    <article>
                        <h3>Confirm Deletion</h3>
                        <p>
                            Are you sure you want to delete firmware version {deleteConfirmation.version}?
                            This action cannot be undone.
                        </p>
                        <footer>
                            <button className="secondary" onClick={handleDeleteCancel}>
                                Cancel
                            </button>
                            <button className="error" onClick={handleDeleteConfirm}>
                                Delete
                            </button>
                        </footer>
                    </article>
                </dialog>
            )}
        </article>
    );
};

export default FirmwareManager; 