import React, { useState, useEffect } from 'react';
import { useAxios } from '../../api/AxiosInstance';
import OrganizationList from '../../components/OrganizationList/OrganizationList';
import OrganizationModal from '../../components/Modals/OrganizationModal';
import './Organizations.css';

const Organizations = () => {
  const [organizations, setOrganizations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const axiosInstance = useAxios();

  const fetchOrganizations = async () => {
    try {
      const response = await axiosInstance.get('/organizations/');
      setOrganizations(response.data);
    } catch (err) {
      setError('Failed to fetch organizations');
      console.error('Error fetching organizations:', err);
    }
  };

  useEffect(() => {
    fetchOrganizations();
  }, []);

  const handleCreate = async (orgData) => {
    try {
      await axiosInstance.post('/organizations/create', orgData);
      fetchOrganizations();
      setIsModalOpen(false);
    } catch (err) {
      setError('Failed to create organization');
      console.error('Error creating organization:', err);
    }
  };

  const handleDelete = async (orgName) => {
    if (window.confirm('Are you sure you want to delete this organization?')) {
      try {
        await axiosInstance.post(`/organizations/${orgName}/delete`);
        fetchOrganizations();
      } catch (err) {
        setError('Failed to delete organization');
        console.error('Error deleting organization:', err);
      }
    }
  };

  return (
    <main className="container">
      <div className="header-container">
        <h2>Organizations</h2>
        <button 
          className="primary create-organization-button"
          onClick={() => setIsModalOpen(true)}
        >
          Create Organization
        </button>
      </div>

      {error && (
        <article aria-label="Error message" className="error">
          {error}
        </article>
      )}

      <OrganizationList
        organizations={organizations}
        onDelete={handleDelete}
      />

      {isModalOpen && (
        <OrganizationModal
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleCreate}
        />
      )}
    </main>
  );
};

export default Organizations;
