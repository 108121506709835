import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDeviceApi } from '../../api/device';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './DeviceSettings.css';
import GatewaySettings from '../../components/GatewaySettings/GatewaySettings';
import ControllerSettings from '../../components/ControllerSettings/ControllerSettings';

const DeviceSettings = () => {
  const { deviceName } = useParams();
  const deviceApi = useDeviceApi();
  const [deviceInfo, setDeviceInfo] = useState(null);
  const [settings, setSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [rebooting, setRebooting] = useState(false);

  // Fetch device info and settings
  const fetchDeviceData = async () => {
    try {
      setLoading(true);
      
      // Get device info first to determine the device type
      const infoResponse = await deviceApi.getDeviceInfo(deviceName);
      setDeviceInfo(infoResponse);
      
      // Get device settings
      const settingsResponse = await deviceApi.getDeviceSettings(deviceName);
      setSettings(settingsResponse);
      
      setLoading(false);
    } catch (error) {
      console.error("Error fetching device data:", error);
      toast.error("Failed to fetch device settings.");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDeviceData();
    // We don't need polling here as settings are only changed by the user
    // through this interface
  }, [deviceName]);

  // Handle settings updates
  const handleSettingsChange = (newSettings) => {
    setSettings(newSettings);
  };

  // Save settings to the device
  const saveSettings = async () => {
    if (!settings) return;
    
    try {
      setSaving(true);
      await deviceApi.updateDeviceSettings(deviceName, settings);
      toast.success("Settings saved successfully.");
      // Refresh settings after saving
      await fetchDeviceData();
      setSaving(false);
    } catch (error) {
      console.error("Error saving settings:", error);
      toast.error(error.response?.data?.detail || "Failed to save settings.");
      setSaving(false);
    }
  };

  // Reboot device
  const rebootDevice = async () => {
    try {
      setRebooting(true);
      await deviceApi.rebootDevice(deviceName);
      toast.info("Device reboot initiated.");
      setRebooting(false);
    } catch (error) {
      console.error("Error rebooting device:", error);
      toast.error(error.response?.data?.detail || "Failed to reboot device.");
      setRebooting(false);
    }
  };

  // Render loading state
  if (loading) {
    return (
      <div className="settings-page">
        <h2>Device Settings</h2>
        <div className="loading-message">Loading device settings...</div>
      </div>
    );
  }

  // Render error state if no device info
  if (!deviceInfo) {
    return (
      <div className="settings-page">
        <h2>Device Settings</h2>
        <div className="error-message">
          Could not load device information. Please try again later.
        </div>
      </div>
    );
  }

  return (
    <div className="settings-page">
      <div className="settings-header">
        <h2>Settings for {deviceInfo.display_name}</h2>
        <div className="device-info">
          <div>Type: {deviceInfo.device_type}</div>
          <div>Connection: {deviceInfo.connected ? "Connected" : "Disconnected"}</div>
        </div>
      </div>

      {settings ? (
        <div className="settings-content">
          {deviceInfo.device_type === "GATEWAY_V1" ? (
            <GatewaySettings 
              settings={settings} 
              onChange={handleSettingsChange} 
            />
          ) : deviceInfo.device_type === "PPM_CONTROLLER_V2" ? (
            <ControllerSettings 
              settings={settings} 
              onChange={handleSettingsChange} 
            />
          ) : (
            <div className="unknown-device-type">
              Unsupported device type: {deviceInfo.device_type}
            </div>
          )}

          <div className="settings-note">
            Note: Device requires a reboot for settings changes to take effect.
          </div>

          <div className="settings-actions">
            <div className="button-group">
              <button 
                onClick={saveSettings} 
                disabled={saving || !deviceInfo.connected}
                className="save-button"
              >
                {saving ? "Saving..." : "Save Settings"}
              </button>
              <button 
                onClick={rebootDevice} 
                disabled={rebooting || !deviceInfo.connected}
                className="reboot-button"
              >
                {rebooting ? "Rebooting..." : "Reboot Device"}
              </button>
            </div>
            {!deviceInfo.connected && (
              <div className="disconnected-warning">
                Device is currently disconnected. Cannot save settings or reboot.
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="no-settings">
          No settings available for this device.
        </div>
      )}
      
      <ToastContainer position="bottom-right" autoClose={3000} />
    </div>
  );
};

export default DeviceSettings;
