import React, { useState, useEffect } from 'react';
import { useAxios } from '../../api/AxiosInstance';

const DeviceManagementModal = ({ organizationName, onClose }) => {
  const [orgDevices, setOrgDevices] = useState([]); // Devices in the organization
  const [allDevices, setAllDevices] = useState([]); // All other devices
  const axiosInstance = useAxios();

  // Fetch devices when component mounts
  useEffect(() => {
    const fetchDevices = async () => {
      try {
        // Fetch organization devices
        const orgResponse = await axiosInstance.get(`/organizations/${organizationName}/`);
        setOrgDevices(orgResponse.data.devices);

        // Fetch all devices and filter out those already in the organization
        const allDevicesResponse = await axiosInstance.get('/devices/');
        const filteredDevices = allDevicesResponse.data.filter(
          (device) => !orgResponse.data.devices.some((orgDevice) => orgDevice.device_name === device.device_name)
        );
        setAllDevices(filteredDevices);
      } catch (error) {
        console.error('Error fetching devices:', error);
      }
    };

    fetchDevices();
  }, [organizationName, axiosInstance]);

  // Add device to organization
  const handleAddDevice = async (deviceName) => {
    try {
      await axiosInstance.post(`/organizations/${organizationName}/add_device/`, { device_name: deviceName });
      setOrgDevices((prev) => [...prev, { device_name: deviceName }]);
      setAllDevices((prev) => prev.filter((device) => device.device_name !== deviceName));
    } catch (error) {
      console.error('Error adding device:', error);
    }
  };

  // Remove device from organization
  const handleRemoveDevice = async (deviceName) => {
    try {
      await axiosInstance.post(`/organizations/${organizationName}/remove_device/`, { device_name: deviceName });
      setAllDevices((prev) => [...prev, { device_name: deviceName }]);
      setOrgDevices((prev) => prev.filter((device) => device.device_name !== deviceName));
    } catch (error) {
      console.error('Error removing device:', error);
    }
  };

  return (
    <dialog open className="modal" style={{ maxWidth: '600px' }}>
      <article>
        <header>
          <h2>Manage Devices for {organizationName}</h2>
          <button aria-label="Close" className="secondary" onClick={onClose}>&times;</button>
        </header>

        <h3>Devices in Organization</h3>
        <ul role="list" className="device-list">
          {orgDevices.map((device) => (
            <li key={device.device_name} className="device-item">
              <span>{device.device_display_name} - {device.device_type}</span>
              <button className="contrast" onClick={() => handleRemoveDevice(device.device_name)}>
                Remove
              </button>
            </li>
          ))}
        </ul>

        <h3>All Other Devices</h3>
        <ul role="list" className="device-list">
          {allDevices.map((device) => (
            <li key={device.device_name} className="device-item">
              <span>{device.device_display_name} - {device.device_type}</span>
              <button className="secondary" onClick={() => handleAddDevice(device.device_name)}>
                Add
              </button>
            </li>
          ))}
        </ul>

        <footer>
          <button type="button" className="secondary" onClick={onClose}>
            Close
          </button>
        </footer>
      </article>
    </dialog>
  );
};

export default DeviceManagementModal;
