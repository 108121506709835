import React, { useState, useEffect, useCallback } from 'react';
import { useAxios } from '../../api/AxiosInstance';
import { toast } from 'react-toastify';
import './DeviceFirmwareManager.css';

// OTA Status enum matching backend
const OTA_STATUS = {
  IDLE: 0,
  WAITING_START: 1,
  DOWNLOADING: 2,
  WAITING_VERIFY: 3,
  SUCCESS: 4,
  FAILED: 5
};

// Map status to human-readable text
const OTA_STATUS_TEXT = {
  [OTA_STATUS.IDLE]: 'Idle',
  [OTA_STATUS.WAITING_START]: 'Waiting to start',
  [OTA_STATUS.DOWNLOADING]: 'Downloading firmware',
  [OTA_STATUS.WAITING_VERIFY]: 'Verifying firmware',
  [OTA_STATUS.SUCCESS]: 'Update successful',
  [OTA_STATUS.FAILED]: 'Update failed'
};

const DeviceFirmwareManager = () => {
  // Basic state
  const [devices, setDevices] = useState([]);
  const [deviceDetails, setDeviceDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [updating, setUpdating] = useState(false);
  
  // Firmware state
  const [firmwareList, setFirmwareList] = useState([]);
  const [availableBranches, setAvailableBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState('');
  const [availableFirmware, setAvailableFirmware] = useState([]);
  const [selectedFirmware, setSelectedFirmware] = useState('');
  
  // Hardware type tracking
  const [multipleHardwareTypes, setMultipleHardwareTypes] = useState(false);
  const [selectedHardwareType, setSelectedHardwareType] = useState('');
  
  // OTA update state
  const [otaData, setOtaData] = useState({});
  
  // Flag to prevent multiple fetches
  const [dataFetched, setDataFetched] = useState(false);
  
  const axiosInstance = useAxios();
  const v2Api = axiosInstance.getV2();

  // Helper function for API requests with retries
  const safeApiCall = async (apiCall, errorMsg, maxRetries = 2) => {
    let retries = 0;
    while (retries <= maxRetries) {
      try {
        return await apiCall();
      } catch (err) {
        if (retries === maxRetries) {
          console.error(errorMsg, err);
          return null;
        }
        // Wait a bit before retrying (exponential backoff)
        await new Promise(r => setTimeout(r, 500 * Math.pow(2, retries)));
        retries++;
      }
    }
  };

  // Function for fetching devices - not memoized to avoid dependency issues
  const fetchDevices = async () => {
    // Don't fetch if we've already done it
    if (dataFetched) return;
    
    try {
      setLoading(true);
      
      // Step 1: Get connected devices
      const devicesResponse = await safeApiCall(
        () => axiosInstance.get('/devices/'),
        'Error fetching devices:'
      );
      
      if (!devicesResponse) {
        toast.error('Failed to fetch devices');
        setLoading(false);
        return;
      }
      
      // Filter to only connected devices
      const connectedDevices = devicesResponse.data.filter(device => device.connected);
      setDevices(connectedDevices);
      
      // Step 2: Get firmware list (one request)
      const firmwareResponse = await safeApiCall(
        () => v2Api.get('/firmware/list'),
        'Error fetching firmware list:'
      );
      
      if (firmwareResponse) {
        setFirmwareList(firmwareResponse.data);
      }
      
      // Step 3: Fetch device info one by one to avoid resource exhaustion
      const deviceInfoMap = {};
      const otaDataMap = {};
      
      for (const device of connectedDevices) {
        // Get device info
        const deviceInfo = await safeApiCall(
          () => v2Api.get(`/devices/${device.device_name}/info`),
          `Error fetching info for device ${device.device_name}:`
        );
        
        if (deviceInfo) {
          deviceInfoMap[device.device_name] = deviceInfo.data;
        }
        
        // Get OTA data for each device
        const otaInfo = await safeApiCall(
          () => v2Api.get(`/devices/${device.device_name}/ota-status`),
          `Error fetching OTA info for device ${device.device_name}:`
        );
        
        if (otaInfo) {
          otaDataMap[device.device_name] = otaInfo.data;
          
          // Show toast for failed updates
          if (otaInfo.data.status === OTA_STATUS.FAILED && otaInfo.data.error_message) {
            toast.error(`Firmware update failed for ${device.device_display_name}: ${otaInfo.data.error_message}`);
          }
        } else {
          // Default to IDLE if we couldn't get OTA status
          otaDataMap[device.device_name] = {
            status: OTA_STATUS.IDLE,
            progress: 0,
            error_message: null,
            estimated_time_remaining_s: null
          };
        }
        
        // Small delay between requests to avoid overwhelming the server
        await new Promise(r => setTimeout(r, 100));
      }
      
      setDeviceDetails(deviceInfoMap);
      setOtaData(otaDataMap);
      setDataFetched(true); // Mark data as fetched
    } catch (error) {
      console.error('Error in fetchDevices:', error);
      toast.error('Failed to fetch device data');
    } finally {
      setLoading(false);
    }
  };

  // Function to refresh OTA data only for devices with active updates
  const refreshOtaData = async () => {
    // Find devices with non-idle OTA status
    const activeDevices = Object.keys(otaData).filter(
      deviceName => otaData[deviceName] && otaData[deviceName].status !== OTA_STATUS.IDLE
    );
    
    if (activeDevices.length === 0) return;
    
    // Refresh OTA data for each active device
    const updatedOtaData = { ...otaData };
    
    for (const deviceName of activeDevices) {
      const otaInfo = await safeApiCall(
        () => v2Api.get(`/devices/${deviceName}/ota-status`),
        `Error refreshing OTA info for device ${deviceName}:`
      );
      
      if (otaInfo) {
        updatedOtaData[deviceName] = otaInfo.data;
        
        // Check if status transitioned to failed
        if (
          otaInfo.data.status === OTA_STATUS.FAILED && 
          otaData[deviceName].status !== OTA_STATUS.FAILED && 
          otaInfo.data.error_message
        ) {
          const device = devices.find(d => d.device_name === deviceName);
          const deviceDisplayName = device ? device.device_display_name : deviceName;
          toast.error(`Firmware update failed for ${deviceDisplayName}: ${otaInfo.data.error_message}`);
        }
        
        // Check if status transitioned to success
        if (
          otaInfo.data.status === OTA_STATUS.SUCCESS && 
          otaData[deviceName].status !== OTA_STATUS.SUCCESS
        ) {
          const device = devices.find(d => d.device_name === deviceName);
          const deviceDisplayName = device ? device.device_display_name : deviceName;
          toast.success(`Firmware update successful for ${deviceDisplayName}`);
        }
      }
    }
    
    setOtaData(updatedOtaData);
  };

  // Load devices once on mount
  useEffect(() => {
    // Only fetch if we haven't fetched already
    if (!dataFetched) {
      fetchDevices();
    }
  }, [dataFetched]);

  // Periodically refresh OTA data for non-idle devices
  useEffect(() => {
    // Skip if no devices or still loading
    if (loading || devices.length === 0) return;
    
    const activeUpdateCount = Object.values(otaData).filter(
      data => data && data.status !== OTA_STATUS.IDLE
    ).length;
    
    // Only set up interval if there are active updates
    if (activeUpdateCount > 0) {
      const interval = setInterval(() => {
        refreshOtaData();
      }, 1000); // Refresh every second
      
      return () => clearInterval(interval);
    }
  }, [otaData, devices, loading]);

  // Handle device selection changes
  useEffect(() => {
    if (selectedDevices.length === 0) {
      // Reset everything when no devices selected
      setSelectedHardwareType('');
      setMultipleHardwareTypes(false);
      setAvailableBranches([]);
      setSelectedBranch('');
      setAvailableFirmware([]);
      setSelectedFirmware('');
      return;
    }

    // Get unique hardware types from selected devices
    const hardwareTypes = new Set();
    selectedDevices.forEach(deviceId => {
      const device = devices.find(d => d.device_name === deviceId);
      if (device?.device_type) {
        hardwareTypes.add(device.device_type);
      }
    });

    // Check if we have multiple hardware types
    if (hardwareTypes.size > 1) {
      setMultipleHardwareTypes(true);
      setSelectedHardwareType('');
      setAvailableBranches([]);
      setSelectedBranch('');
      setAvailableFirmware([]);
      setSelectedFirmware('');
    } else if (hardwareTypes.size === 1) {
      // Single hardware type
      const hardwareType = Array.from(hardwareTypes)[0];
      setMultipleHardwareTypes(false);
      setSelectedHardwareType(hardwareType);
      
      // Get available branches for this hardware type
      const branches = new Set();
      firmwareList
        .filter(fw => fw.hardware_type === hardwareType)
        .forEach(fw => branches.add(fw.git_branch));
      
      setAvailableBranches(Array.from(branches));
      setSelectedBranch(''); // Reset branch selection
    }
  }, [selectedDevices, devices, firmwareList]);

  // Handle branch selection changes
  useEffect(() => {
    // Clear firmware selection when branch changes
    setSelectedFirmware('');
    
    if (!selectedBranch || !selectedHardwareType) {
      setAvailableFirmware([]);
      return;
    }

    // Filter firmware by hardware type and branch
    const filteredFirmware = firmwareList.filter(
      fw => fw.hardware_type === selectedHardwareType && fw.git_branch === selectedBranch
    );

    // Sort by version (newest first)
    const sortedFirmware = [...filteredFirmware].sort((a, b) => {
      if (a.version_major !== b.version_major) return b.version_major - a.version_major;
      if (a.version_minor !== b.version_minor) return b.version_minor - a.version_minor;
      if (a.version_patch !== b.version_patch) return b.version_patch - a.version_patch;
      return b.version_build - a.version_build;
    });

    setAvailableFirmware(sortedFirmware);
  }, [selectedBranch, selectedHardwareType, firmwareList]);

  // Handle UI interactions
  const handleDeviceSelection = (deviceId) => {
    setSelectedDevices(prev => {
      if (prev.includes(deviceId)) {
        return prev.filter(id => id !== deviceId);
      } else {
        return [...prev, deviceId];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedDevices.length === devices.length) {
      setSelectedDevices([]);
    } else {
      setSelectedDevices(devices.map(device => device.device_name));
    }
  };

  const handleBranchChange = (e) => {
    setSelectedBranch(e.target.value);
  };

  const handleFirmwareChange = (e) => {
    setSelectedFirmware(e.target.value);
  };

  // Updated to handle the nested structure from the API
  const getCurrentFirmwareVersion = (device) => {
    const info = deviceDetails[device.device_name];
    if (!info) return 'Unknown';
    
    // Extract from nested device_info structure
    const deviceInfo = info.device_info;
    if (!deviceInfo) return 'Unknown';
    
    const fw_version_major = deviceInfo.fw_version_major ?? 'Unknown';
    const fw_version_minor = deviceInfo.fw_version_minor ?? 'Unknown';
    const fw_version_patch = deviceInfo.fw_version_patch ?? 'Unknown';
    const fw_version_build = deviceInfo.fw_version_build ?? 'Unknown';
    const fw_branch_name = deviceInfo.fw_branch_name ?? 'Unknown';
    
    return `${fw_branch_name} ${fw_version_major}.${fw_version_minor}.${fw_version_patch} (${fw_version_build})`;
  };

  // Filter devices for display in tables
  const getDevicesWithActiveUpdates = () => {
    return devices.filter(device => 
      otaData[device.device_name] && 
      otaData[device.device_name].status !== OTA_STATUS.IDLE
    );
  };

  const getDevicesWithoutActiveUpdates = () => {
    return devices.filter(device => 
      !otaData[device.device_name] || 
      otaData[device.device_name].status === OTA_STATUS.IDLE
    );
  };

  // Format OTA progress for display
  const formatOtaProgress = (progress) => {
    return `${Math.round(progress * 100)}%`;
  };

  // Format time remaining
  const formatTimeRemaining = (seconds) => {
    if (!seconds) return 'Calculating...';
    
    if (seconds < 60) {
      return `${Math.round(seconds)} seconds`;
    } else {
      const minutes = Math.round(seconds / 60);
      return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
  };

  // API interactions - sequential updates instead of parallel
  const handleUpdateToLatest = async () => {
    if (selectedDevices.length === 0) {
      toast.warning('Please select at least one device');
      return;
    }

    try {
      setUpdating(true);
      let successCount = 0;
      
      for (const deviceId of selectedDevices) {
        try {
          await v2Api.post(`/devices/${deviceId}/update-firmware`);
          successCount++;
          // Small delay between requests
          await new Promise(r => setTimeout(r, 100));
        } catch (error) {
          console.error(`Error updating device ${deviceId}:`, error);
        }
      }
      
      if (successCount === selectedDevices.length) {
        toast.success(`Update to latest firmware initiated for all ${successCount} device(s)`);
      } else if (successCount > 0) {
        toast.warning(`Update initiated for ${successCount} of ${selectedDevices.length} devices. Some updates failed.`);
      } else {
        toast.error('Failed to update any devices');
      }
      
      // Refresh data after updates
      setTimeout(() => {
        setDataFetched(false); 
        fetchDevices();
      }, 1000);

    } catch (error) {
      console.error('Error in update process:', error);
      toast.error('Failed to update firmware');
    } finally {
      setUpdating(false);
    }
  };

  const handleUpdateToSelected = async () => {
    if (selectedDevices.length === 0) {
      toast.warning('Please select at least one device');
      return;
    }

    if (!selectedFirmware) {
      toast.warning('Please select a firmware version');
      return;
    }

    try {
      setUpdating(true);
      let successCount = 0;
      
      for (const deviceId of selectedDevices) {
        try {
          await v2Api.post(`/devices/${deviceId}/update-firmware/${selectedFirmware}`);
          successCount++;
          // Small delay between requests
          await new Promise(r => setTimeout(r, 100));
        } catch (error) {
          console.error(`Error updating device ${deviceId} to specific firmware:`, error);
        }
      }
      
      if (successCount === selectedDevices.length) {
        toast.success(`Update to selected firmware initiated for all ${successCount} device(s)`);
      } else if (successCount > 0) {
        toast.warning(`Update initiated for ${successCount} of ${selectedDevices.length} devices. Some updates failed.`);
      } else {
        toast.error('Failed to update any devices');
      }
      
      // Refresh data after updates
      setTimeout(() => {
        setDataFetched(false); 
        fetchDevices();
      }, 1000);
      
    } catch (error) {
      console.error('Error in update process:', error);
      toast.error('Failed to update firmware');
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className="device-firmware-manager">
      <h3>Device Firmware Updates</h3>
      
      {loading ? (
        <div className="loading">Loading devices...</div>
      ) : devices.length === 0 ? (
        <div className="no-devices-message">
          <p>No connected devices found. Please make sure devices are connected before attempting firmware updates.</p>
        </div>
      ) : (
        <>
          {/* Active OTA Updates Table */}
          {getDevicesWithActiveUpdates().length > 0 && (
            <div className="ota-updates-section">
              <h4>Active Firmware Updates</h4>
              <table className="ota-table">
                <thead>
                  <tr>
                    <th>Device</th>
                    <th>Status</th>
                    <th>Progress</th>
                    <th>Est. Time Remaining</th>
                    {Object.values(otaData).some(data => data && data.status === OTA_STATUS.FAILED) && <th>Error</th>}
                  </tr>
                </thead>
                <tbody>
                  {getDevicesWithActiveUpdates().map(device => {
                    const deviceOta = otaData[device.device_name] || {};
                    return (
                      <tr key={`ota-${device.device_name}`} className={`ota-status-${deviceOta.status}`}>
                        <td>{device.device_display_name}</td>
                        <td>{OTA_STATUS_TEXT[deviceOta.status] || 'Unknown'}</td>
                        <td>
                          <div className="progress-bar-container">
                            <div 
                              className="progress-bar" 
                              style={{ width: `${Math.round(deviceOta.progress * 100)}%` }}
                            ></div>
                            <span className="progress-text">{formatOtaProgress(deviceOta.progress)}</span>
                          </div>
                        </td>
                        <td>
                          {deviceOta.status === OTA_STATUS.DOWNLOADING && 
                           deviceOta.estimated_time_remaining_s !== null ? 
                            formatTimeRemaining(deviceOta.estimated_time_remaining_s) : '-'}
                        </td>
                        {Object.values(otaData).some(data => data && data.status === OTA_STATUS.FAILED) && (
                          <td className="error-message">
                            {deviceOta.status === OTA_STATUS.FAILED ? deviceOta.error_message || 'Unknown error' : ''}
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}

          {/* Regular Device Selection */}
          <div className="device-selection">
            <h4>Select Connected Devices</h4>
            <div className="select-all">
              <button 
                onClick={handleSelectAll}
                className="select-all-button"
              >
                {selectedDevices.length === getDevicesWithoutActiveUpdates().length ? 'Deselect All' : 'Select All'}
              </button>
            </div>

            <table className="devices-table">
              <thead>
                <tr>
                  <th></th>
                  <th>Device Name</th>
                  <th>Hardware Type</th>
                  <th>Current Firmware</th>
                </tr>
              </thead>
              <tbody>
                {getDevicesWithoutActiveUpdates().map((device) => (
                  <tr key={device.device_name}>
                    <td>
                      <input
                        type="checkbox"
                        checked={selectedDevices.includes(device.device_name)}
                        onChange={() => handleDeviceSelection(device.device_name)}
                      />
                    </td>
                    <td>{device.device_display_name}</td>
                    <td>{device.device_type}</td>
                    <td>{getCurrentFirmwareVersion(device)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="firmware-update-section">
            <h4>Update Firmware</h4>
            
            <div className="update-options">
              {selectedDevices.length > 0 && (
                <div className="update-to-latest">
                  <button
                    onClick={handleUpdateToLatest}
                    disabled={updating || selectedDevices.length === 0}
                    className="update-button"
                  >
                    Update to Latest Firmware
                  </button>
                  <p className="hint">Updates all selected devices to the latest firmware on their current branch</p>
                </div>
              )}

              {selectedDevices.length > 0 && !multipleHardwareTypes && (
                <div className="update-to-specific">
                  <div className="firmware-selection">
                    <div className="form-group">
                      <label htmlFor="branch-select">Firmware Version:</label>
                      <div className="select-container">
                        <select 
                          id="branch-select"
                          value={selectedBranch}
                          onChange={handleBranchChange}
                          disabled={updating}
                          className="branch-select"
                        >
                          <option value="">Select a branch</option>
                          {availableBranches.map(branch => (
                            <option key={branch} value={branch}>{branch}</option>
                          ))}
                        </select>
                        
                        {selectedBranch && (
                          <select
                            id="firmware-select"
                            value={selectedFirmware}
                            onChange={handleFirmwareChange}
                            disabled={updating || !selectedBranch}
                            className="firmware-select"
                          >
                            <option value="">Select a version</option>
                            {availableFirmware.map(fw => (
                              <option key={fw.id} value={fw.id}>
                                {`${fw.version_major}.${fw.version_minor}.${fw.version_patch} (${fw.version_build})`}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                  </div>

                  <button
                    onClick={handleUpdateToSelected}
                    disabled={updating || !selectedFirmware}
                    className="update-button"
                  >
                    Update to Selected Firmware
                  </button>
                </div>
              )}

              {multipleHardwareTypes && selectedDevices.length > 0 && (
                <div className="warning-message">
                  <p>Multiple hardware types selected. Only "Update to Latest" is available.</p>
                </div>
              )}

              {selectedDevices.length === 0 && (
                <div className="empty-selection-message">
                  <p>Please select at least one device to update</p>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DeviceFirmwareManager; 