// OrganizationModal.js
import React, { useState } from 'react';

const OrganizationModal = ({ onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    name: '',
    description: ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <dialog open>
      <article>
        <header>
          <h3>Create Organization</h3>
        </header>
        <form onSubmit={handleSubmit}>
          <label htmlFor="name">
            Name
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={(e) => setFormData({...formData, name: e.target.value})}
              required
            />
          </label>

          <label htmlFor="description">
            Description
            <textarea
              id="description"
              name="description"
              value={formData.description}
              onChange={(e) => setFormData({...formData, description: e.target.value})}
              required
            />
          </label>

          <footer>
            <button type="submit" className="primary">Create</button>
            <button type="button" className="secondary" onClick={onClose}>Cancel</button>
          </footer>
        </form>
      </article>
    </dialog>
  );
};

export default OrganizationModal;

