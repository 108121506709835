import React, { useEffect, useRef, useState, memo } from 'react';
import * as d3 from 'd3';
import moment from 'moment-timezone';
import { useAxios } from '../../api/AxiosInstance';

function utcToLocal(utcTime) {
    return moment.utc(utcTime).local().toDate();
}

const FlagChart = memo(function FlagChart({ deviceName, flags = [] }) {
    const svgRef = useRef();
    const axiosInstance = useAxios();
    const [selectedFlags, setSelectedFlags] = useState([]); // Flags currently selected by the user
    const [flagData, setFlagData] = useState({}); // Store fetched data for each flag
    const [selectedRange, setSelectedRange] = useState('24hr');
    const [scrollOffset, setScrollOffset] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [dragStartX, setDragStartX] = useState(0);
    const [scrollOffsetMax, setScrollOffsetMax] = useState(0);
    const [scrollOffsetMin, setScrollOffsetMin] = useState(0);
    const [hoverData, setHoverData] = useState(null);
    const [mousePos, setMousePos] = useState({ x: 0, y: 0 });

    const timeRanges = {
        '1hr': 1 * 60 * 60 * 1000,
        '6hr': 6 * 60 * 60 * 1000,
        '24hr': 24 * 60 * 60 * 1000,
        '1W': 7 * 24 * 60 * 60 * 1000,
        '1M': 30 * 24 * 60 * 60 * 1000,
        '1Y': 365 * 24 * 60 * 60 * 1000,
    };

    const handleRangeChange = (range) => {
        setSelectedRange(range);
        setScrollOffset((prevOffset) => {
            const timeRangeMillis = timeRanges[range];
            const currentRange = timeRanges[selectedRange];
            const newOffset = (prevOffset * currentRange) / timeRangeMillis;
            return Math.max(scrollOffsetMin, Math.min(scrollOffsetMax, newOffset));
        });
    };

    const fetchFlagData = async (flagName) => {
        try {
            const response = await axiosInstance.get(`/devices/${deviceName}/flags/${flagName}/`);
            setFlagData((prevData) => ({
                ...prevData,
                [flagName]: response.data.map((d) => ({
                    ...d,
                    timestamp_local: utcToLocal(d.timestamp),
                })),
            }));
        } catch (error) {
            console.error(`Error fetching data for flag ${flagName}:`, error);
        }
    };

    const toggleFlag = (flagName) => {
        if (selectedFlags.includes(flagName)) {
            // Deselect flag
            setSelectedFlags((prev) => prev.filter((name) => name !== flagName));
        } else {
            // Select flag and fetch data if not already fetched
            setSelectedFlags((prev) => [...prev, flagName]);
            if (!flagData[flagName]) fetchFlagData(flagName);
        }
    };

    useEffect(() => {
        d3.select(svgRef.current).selectAll('*').remove();
        if (selectedFlags.length === 0) return;

        const width = svgRef.current.clientWidth;
        const height = 400;
        const margin = { top: 20, right: 30, bottom: 30, left: 60 };

        const flattenedData = selectedFlags.flatMap((flagName) =>
            (flagData[flagName] || []).map((d) => ({
                ...d,
                flagName,
            }))
        );

        const timeRangeMillis = timeRanges[selectedRange];

        if (flattenedData.length === 0) {
            console.error('Error: No data found for selected flags');
            return;
        }

        const endTime = d3.max(flattenedData, (d) => new Date(d.timestamp_local));
        const dataEarliest = d3.min(flattenedData, (d) => new Date(d.timestamp_local));

        if (!endTime || !dataEarliest)
            return;

        setScrollOffsetMin(1 - svgRef.current.clientWidth);
        setScrollOffsetMax(
            ((endTime.getTime() - dataEarliest.getTime()) * svgRef.current.clientWidth) / timeRangeMillis
        );

        const adjustedEndTime = new Date(
            endTime.getTime() - (scrollOffset * timeRangeMillis) / svgRef.current.clientWidth
        );
        const startTime = new Date(adjustedEndTime.getTime() - timeRangeMillis);

        const visibleData = flattenedData.filter(
            (d) => new Date(d.timestamp_local) >= startTime && new Date(d.timestamp_local) <= adjustedEndTime
        );

        // Sort data by timestamp
        const sorted_flattenedData = visibleData.sort((a, b) => new Date(a.timestamp_local) - new Date(b.timestamp_local));

        //New data with timestamp_start and timestamp_end for showing the active period of the flag
        const activityData = [];
        let activeFlag = false;
        let activeFlagName = '';
        let activeFlagSeverity = '';
        let activeFlagDescription = '';
        let activeFlagStart = '';
        let activeFlagEnd = '';

        if (sorted_flattenedData[0].active == false) {
            activeFlag = true;
            activeFlagName = sorted_flattenedData[0].flagName;
            activeFlagSeverity = sorted_flattenedData[0].severity;
            activeFlagDescription = sorted_flattenedData[0].description;
            activeFlagStart = startTime;
        }

        for (let i = 0; i < sorted_flattenedData.length; i++) {
            if (sorted_flattenedData[i].active && !activeFlag) {
                activeFlag = true;
                activeFlagName = sorted_flattenedData[i].flagName;
                activeFlagStart = sorted_flattenedData[i].timestamp_local;
                activeFlagSeverity = sorted_flattenedData[i].severity;
                activeFlagDescription = sorted_flattenedData[i].description;
            }
            if (!sorted_flattenedData[i].active && activeFlag) {
                activeFlag = false;
                activeFlagEnd = sorted_flattenedData[i].timestamp_local;
                activityData.push({
                    name: activeFlagName,
                    severity: activeFlagSeverity,
                    description: activeFlagDescription,
                    timestamp_start: activeFlagStart,
                    timestamp_end: activeFlagEnd,
                });
            }
        }

        if (activeFlag) {
            activityData.push({
                name: activeFlagName,
                severity: activeFlagSeverity,
                description: activeFlagDescription,
                timestamp_start: activeFlagStart,
                timestamp_end: adjustedEndTime,
            });
        }

        const svg = d3.select(svgRef.current).attr('width', width).attr('height', height);

        const xScale = d3
            .scaleTime()
            .domain([startTime, adjustedEndTime])
            .range([margin.left, width - margin.right]);

        const yScale = d3
            .scalePoint()
            .domain(selectedFlags)
            .range([height - margin.bottom, margin.top])
            .padding(0.5);

        svg
            .append('g')
            .attr('transform', `translate(0,${height - margin.bottom})`)
            .call(d3.axisBottom(xScale));

        svg
            .append('g')
            .attr('transform', `translate(${margin.left}, 0)`)
            .call(d3.axisLeft(yScale));

        svg
            .selectAll('.flag-line')
            .data(activityData)
            .enter()
            .append('rect')
            .attr('x', (d) => xScale(new Date(d.timestamp_start)))
            .attr('y', (d) => yScale(d.name) - 5)
            .attr('width', (d) => xScale(new Date(d.timestamp_end)) - xScale(new Date(d.timestamp_start))+2)
            .attr('height', 10)
            .attr('fill', (d) => (d.severity === 'high' ? 'red' : 'orange'))
            .on('mouseover', (event, d) => {
                setHoverData(d);
                console.log("hoverData", d);
                const [mouseX, mouseY] = d3.pointer(event);
                setMousePos({ x: mouseX, y: mouseY });
            })
            .on('mouseout', () => setHoverData(null));
    }, [flagData, selectedFlags, selectedRange, scrollOffset]);

    const handleMouseDown = (event) => {
        setIsDragging(true);
        setDragStartX(event.clientX);
    };

    const handleMouseMove = (event) => {
        if (!isDragging) return;
        const dx = event.clientX - dragStartX;
        const newOffset = Math.max(
            scrollOffsetMin,
            Math.min(scrollOffsetMax, scrollOffset + dx)
        );
        setScrollOffset(newOffset);
        setDragStartX(event.clientX);
    };

    const handleMouseUp = () => setIsDragging(false);

    return (
        <div
            style={{ overflow: 'hidden', position: 'relative' }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
        >
            <div style={{ marginBottom: '10px', paddingLeft: '20px' }}>
                {/* Time Range Buttons */}
                {Object.keys(timeRanges).map((range) => (
                    <button
                        key={range}
                        onClick={() => handleRangeChange(range)}
                        style={{
                            marginRight: '5px',
                            backgroundColor: selectedRange === range ? 'steelblue' : '#f0f0f0',
                            color: selectedRange === range ? 'white' : 'black',
                            border: 'none',
                            borderRadius: '5px',
                            padding: '5px 10px',
                            cursor: 'pointer',
                        }}
                    >
                        {range}
                    </button>
                ))}
            </div>

            <div style={{ marginBottom: '10px', paddingLeft: '20px', display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {/* Flag Selection */}
                {flags.map((flag) => (
                    <label key={flag.name} style={{ display: 'flex', alignItems: 'center' }}>
                        <input
                            type="checkbox"
                            checked={selectedFlags.includes(flag.name)}
                            onChange={() => toggleFlag(flag.name)}
                        />
                        <span style={{ marginLeft: '5px' }}>{flag.name}</span>
                    </label>
                ))}
            </div>

            <svg ref={svgRef} style={{ width: '100%', overflow: 'hidden' }}></svg>

            {hoverData && (
                <div
                    style={{
                        position: 'absolute',
                        left: `${mousePos.x + 20}px`,
                        top: `${mousePos.y + 20}px`,
                        backgroundColor: 'gray',
                        padding: '5px',
                        border: '1px solid black',
                        borderRadius: '5px',
                        pointerEvents: 'none',
                    }}
                >
                    <div>Severity: {hoverData.severity}</div>
                    <div>Description: {hoverData.description}</div>
                    <div>Started: {moment(hoverData.timestamp_start).format('MMM D, YYYY h:mm A')}</div>
                    <div>Ended: {moment(hoverData.timestamp_end).format('MMM D, YYYY h:mm A')}</div>
                </div>
            )}
        </div>
    );
});

export default FlagChart;
