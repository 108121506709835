import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import moment from 'moment-timezone';

function utcToLocal(utcTime) {
  return moment.utc(utcTime).local().toDate(); // Returns a Date object
}

function SensorChart({ dataDict }) {
  const svgRef = useRef();
  const [selectedRange, setSelectedRange] = useState('24hr');
  const [scrollOffset, setScrollOffset] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [dragStartX, setDragStartX] = useState(0);
  const [scrollOffsetMax, setScrollOffsetMax] = useState(0);
  const [scrollOffsetMin, setScrollOffsetMin] = useState(0);
  const [showLine, setShowLine] = useState(true); // Toggleable line
  const [hoverData, setHoverData] = useState(null); // Hover state
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 }); // Mouse position

  const timeRanges = {
    '1hr': 1 * 60 * 60 * 1000,
    '6hr': 6 * 60 * 60 * 1000,
    '24hr': 24 * 60 * 60 * 1000,
    '1W': 7 * 24 * 60 * 60 * 1000,
    '1M': 30 * 24 * 60 * 60 * 1000,
    '1Y': 365 * 24 * 60 * 60 * 1000,
    '5Y': 5 * 365 * 24 * 60 * 60 * 1000,
  };

  const handleRangeChange = (range) => {
    setSelectedRange(range);
    setScrollOffset((prevOffset) => {
      const timeRangeMillis = timeRanges[range];
      const currentRange = timeRanges[selectedRange];
      const newOffset = (prevOffset * currentRange) / timeRangeMillis;
      return Math.max(scrollOffsetMin, Math.min(scrollOffsetMax, newOffset));
    });
  };

  useEffect(() => {
    d3.select(svgRef.current).selectAll('*').remove();
    if (!dataDict || Object.keys(dataDict).length === 0) return;

    // Convert dataDict to array of objects
    const sensorData = Object.entries(dataDict).map(([timestamp, value]) => ({
      timestamp,
      sensor_value: parseFloat(value),
    }));

    //Sort data by timestamp
    sensorData.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));

    sensorData.forEach((d) => {
      if (d.timestamp_local) return;
      d.timestamp_local = utcToLocal(d.timestamp);
    });

    const validData = sensorData.filter(
      (d) => d.sensor_value !== null && !isNaN(d.sensor_value)
    );
    if (validData.length === 0) return;

    const endTime = d3.max(validData, (d) => new Date(d.timestamp_local));
    const timeRangeMillis = timeRanges[selectedRange];
    const dataEarliest = d3.min(validData, (d) => new Date(d.timestamp_local));
    setScrollOffsetMin(1 - svgRef.current.clientWidth);
    setScrollOffsetMax(
      ((endTime.getTime() - dataEarliest.getTime()) * svgRef.current.clientWidth) /
      timeRangeMillis
    );

    const adjustedEndTime = new Date(
      endTime.getTime() - (scrollOffset * timeRangeMillis) / svgRef.current.clientWidth
    );
    const startTime = new Date(adjustedEndTime.getTime() - timeRangeMillis);

    const width = svgRef.current.clientWidth;
    const height = 400;
    const margin = { top: 20, right: 30, bottom: 30, left: 40 };

    const visibleData = validData.filter(
      (d) =>
        new Date(d.timestamp_local) >= startTime &&
        new Date(d.timestamp_local) <= adjustedEndTime
    );

    visibleData.sort(
      (a, b) => new Date(a.timestamp_local) - new Date(b.timestamp_local)
    );

    const svg = d3.select(svgRef.current).attr('width', width).attr('height', height);

    const xScale = d3
      .scaleTime()
      .domain([startTime, adjustedEndTime])
      .range([margin.left, width - margin.right]);

    const yScale = d3
      .scaleLinear()
      .domain([
        d3.min(visibleData, (d) => d.sensor_value),
        d3.max(visibleData, (d) => d.sensor_value),
      ])
      .nice()
      .range([height - margin.bottom, margin.top]);

    svg
      .append('g')
      .attr('transform', `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(xScale));
    svg
      .append('g')
      .attr('transform', `translate(${margin.left}, 0)`)
      .call(d3.axisLeft(yScale));

    if (showLine) {
      const line = d3
        .line()
        .x((d) => xScale(new Date(d.timestamp_local)))
        .y((d) => yScale(d.sensor_value));
      svg
        .append('path')
        .datum(visibleData)
        .attr('fill', 'none')
        .attr('stroke', 'steelblue')
        .attr('stroke-width', 1.5)
        .attr('d', line);
    }

    svg
      .selectAll('circle')
      .data(visibleData)
      .enter()
      .append('circle')
      .attr('cx', (d) => xScale(new Date(d.timestamp_local)))
      .attr('cy', (d) => yScale(d.sensor_value))
      .attr('r', 4)
      .attr('fill', 'steelblue')
      .on('mouseover', (event, d) => {
        setHoverData(d);
        const [mouseX, mouseY] = d3.pointer(event);
        setMousePos({ x: mouseX, y: mouseY });
      })
      .on('mouseout', () => setHoverData(null));

    // Data statistics (avg, min, max)
    const avg = d3.mean(visibleData, (d) => d.sensor_value);
    const min = d3.min(visibleData, (d) => d.sensor_value);
    const max = d3.max(visibleData, (d) => d.sensor_value);
    d3.select('#stats').html(`Avg: ${avg.toFixed(2)}, Min: ${min}, Max: ${max}`);
  }, [dataDict, selectedRange, scrollOffset, showLine]);

  const handleMouseDown = (event) => {
    setIsDragging(true);
    setDragStartX(event.clientX);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;
    const dx = event.clientX - dragStartX;
    const newOffset = Math.max(
      scrollOffsetMin,
      Math.min(scrollOffsetMax, scrollOffset + dx)
    );
    setScrollOffset(newOffset);
    setDragStartX(event.clientX);
  };

  const handleMouseUp = () => setIsDragging(false);

  return (
    <div
      style={{ position: 'relative' }}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
    >
      <div
        style={{
          marginBottom: '10px',
          marginLeft: '20px',
          zIndex: 1,
          paddingRight: '20px',
        }}
      >
        {Object.keys(timeRanges).map((range) => (
          <button
            key={range}
            onClick={() => handleRangeChange(range)}
            style={{
              marginRight: '5px',
              backgroundColor: selectedRange === range ? 'steelblue' : '#f0f0f0',
              color: selectedRange === range ? 'white' : 'black',
              border: 'none',
              borderRadius: '5px',
              padding: '5px 10px',
              cursor: 'pointer',
            }}
          >
            {range}
          </button>
        ))}
        <button
          onClick={() => setShowLine((prev) => !prev)}
          style={{
            marginLeft: '10px',
            backgroundColor: showLine ? 'red' : 'green',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            padding: '5px 10px',
            cursor: 'pointer',
          }}
        >
          {showLine ? 'Hide Line' : 'Show Line'}
        </button>
      </div>
      <svg ref={svgRef} style={{ width: '100%', overflow: 'hidden' }}></svg>
      {hoverData && (
        <div
          style={{
            position: 'absolute',
            left: `${mousePos.x + 20}px`,
            top: `${mousePos.y + 20}px`,
            backgroundColor: 'gray',
            padding: '5px',
            border: '1px solid black',
            borderRadius: '5px',
            pointerEvents: 'none',
          }}
        >
          <div>Value: {hoverData.sensor_value}</div>
          <div>
            Timestamp:{' '}
            {moment(hoverData.timestamp_local).format('MMMM Do YYYY, h:mm:ss a')}
          </div>
        </div>
      )}
      <div id="stats" style={{ marginTop: '10px', marginLeft: '40px' }}></div>
    </div>
  );
}

export default SensorChart;
