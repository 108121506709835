import React from 'react';
import './GatewaySettings.css';

const GatewaySettings = ({ 
  settings, 
  onChange, 
  readOnly = false 
}) => {
  const handleChange = (field, value) => {
    if (onChange && !readOnly) {
      onChange({
        ...settings,
        [field]: field === 'server_port' || field.includes('_minutes') 
          ? parseInt(value, 10) 
          : value
      });
    }
  };

  if (!settings) {
    return <div className="settings-loading">Loading gateway settings...</div>;
  }

  return (
    <div className="gateway-settings">
      <div className="gateway-settings-grid">
        {/* Logging Settings */}
        <div className="setting-item">
          <label htmlFor="sd_logging_interval_minutes">SD Logging Interval (minutes):</label>
          <input
            id="sd_logging_interval_minutes"
            type="number"
            min="1"
            max="1440"
            value={settings.sd_logging_interval_minutes}
            onChange={(e) => handleChange('sd_logging_interval_minutes', e.target.value)}
            disabled={readOnly}
          />
          <small>How often data is logged to the SD card (1-1440 minutes)</small>
        </div>

        <div className="setting-item">
          <label htmlFor="self_reporting_interval_minutes">Self Reporting Interval (minutes):</label>
          <input
            id="self_reporting_interval_minutes"
            type="number"
            min="1"
            max="1440"
            value={settings.self_reporting_interval_minutes}
            onChange={(e) => handleChange('self_reporting_interval_minutes', e.target.value)}
            disabled={readOnly}
          />
          <small>How often data is sent to the server (1-1440 minutes)</small>
        </div>

        {/* Network Settings */}
        <div className="setting-item">
          <label htmlFor="apn">APN:</label>
          <input
            id="apn"
            type="text"
            maxLength="32"
            value={settings.apn}
            onChange={(e) => handleChange('apn', e.target.value)}
            disabled={readOnly}
          />
          <small>Cellular network APN</small>
        </div>

        <div className="setting-item">
          <label htmlFor="sim_pin">SIM PIN:</label>
          <input
            id="sim_pin"
            type="text"
            maxLength="8"
            value={settings.sim_pin}
            onChange={(e) => handleChange('sim_pin', e.target.value)}
            disabled={readOnly}
          />
          <small>SIM card PIN (if required)</small>
        </div>

        <div className="setting-item">
          <label htmlFor="server_url">Server URL:</label>
          <input
            id="server_url"
            type="text"
            maxLength="64"
            value={settings.server_url}
            onChange={(e) => handleChange('server_url', e.target.value)}
            disabled={readOnly}
          />
          <small>Server URL for data upload</small>
        </div>

        <div className="setting-item">
          <label htmlFor="server_port">Server Port:</label>
          <input
            id="server_port"
            type="number"
            min="1"
            max="65535"
            value={settings.server_port}
            onChange={(e) => handleChange('server_port', e.target.value)}
            disabled={readOnly}
          />
          <small>Server port (1-65535)</small>
        </div>

        <div className="setting-item">
          <label htmlFor="espnow_binding_phrase">ESP-NOW Binding Phrase:</label>
          <input
            id="espnow_binding_phrase"
            type="text"
            maxLength="32"
            value={settings.espnow_binding_phrase}
            onChange={(e) => handleChange('espnow_binding_phrase', e.target.value)}
            disabled={readOnly}
          />
          <small>Security phrase for ESP-NOW communication</small>
        </div>
      </div>
    </div>
  );
};

export default GatewaySettings; 