import React, { useState } from 'react';
import { useAxios } from '../../api/AxiosInstance';
import UserManagementModal from '../Modals/UserManagementModal';
import DeviceManagementModal from '../Modals/DeviceManagementModal';

const OrganizationList = ({ organizations, onDelete}) => {
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [isDeviceModalOpen, setIsDeviceModalOpen] = useState(false);
  const [orgDetails, setOrgDetails] = useState(null);
  const axiosInstance = useAxios();

  const handleViewDetails = async (orgName) => {
    if (selectedOrg === orgName) {
      setSelectedOrg(null);
      setOrgDetails(null);
    } else {
      try {
        const response = await axiosInstance.get(`/organizations/${orgName}/`);
        setOrgDetails(response.data);
        setSelectedOrg(orgName);
      } catch (err) {
        console.error('Error fetching organization details:', err);
      }
    }
  };

  return (
    <div>
      <div className="organization-grid">
        {organizations.map((org) => (
          <article key={org.name} className="organization-card">
            <header>
              <h3>{org.name}</h3>
            </header>
            <p>{org.description}</p>
            <p><small>Created: {org.created_at}</small></p>
            
            <footer>
              <div className="button-stack">
                <button 
                  className="secondary"
                  onClick={() => handleViewDetails(org.name)}
                >
                  {selectedOrg === org.name && orgDetails ? 'Close Details' : 'View Details'}
                </button>
                <button
                  className="secondary"
                  onClick={() => {
                    setSelectedOrg(org.name);
                    setIsUserModalOpen(true);
                  }}
                >
                  Manage Users
                </button>
                <button
                  className="secondary"
                  onClick={() => {
                    setSelectedOrg(org.name);
                    setIsDeviceModalOpen(true);
                  }}
                >
                  Manage Devices
                </button>
                <button 
                  className="delete-button"
                  onClick={() => onDelete(org.name)}
                >
                  Delete
                </button>
              </div>
            </footer>

            {selectedOrg === org.name && orgDetails && (
              <div className="details-section">
                <h4>Users</h4>
                <ul>
                  {orgDetails.users.map(user => (
                    <li key={user.username}>{user.username} ({user.email})</li>
                  ))}
                </ul>
                <h4>Devices</h4>
                <ul>
                  {orgDetails.devices.map(device => (
                    <li key={device.device_name}>
                      {device.device_display_name} ({device.device_type})
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </article>
        ))}

        {isUserModalOpen && (
          <dialog open className="modal">
            <article>
              <header>
                <h3>Manage Users - {selectedOrg}</h3>
              </header>
              <UserManagementModal
                organizationName={selectedOrg}
                onClose={() => setIsUserModalOpen(false)}
              />
            </article>
          </dialog>
        )}

        {isDeviceModalOpen && (
          <dialog open className="modal">
            <article>
              <header>
                <h3>Manage Devices - {selectedOrg}</h3>
              </header>
              <DeviceManagementModal
                organizationName={selectedOrg}
                onClose={() => setIsDeviceModalOpen(false)}
              />
            </article>
          </dialog>
        )}
      </div>
    </div>
  );
};

export default OrganizationList;
