import React, { useState, useEffect,memo } from 'react';
import { useAxios } from '../../api/AxiosInstance';
import SensorChart from '../SensorChart/SensorChart';
import PropTypes from 'prop-types';
import './SensorChartCard.css';

const SensorChartCard = memo(function SensorChartCard({ sensorName, deviceName }) {
  const [sensorData, setSensorData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const axiosInstance = useAxios();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(`/devices/${deviceName}/sensors/${sensorName}/`);
        setSensorData(response.data);
      } catch (err) {
        setError('Failed to fetch sensor data.');
        console.error('Error fetching sensor data:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [deviceName, sensorName]); // Removed axiosInstance from the dependency array

  let dataDict = {};
  if (sensorData && sensorData.ts_value) {
    dataDict = sensorData.ts_value.reduce((acc, curr) => {
      const [timestamp, value] = Object.entries(curr)[0];
      acc[timestamp] = value;
      return acc;
    }, {});
  }

  return (
    <div className="sensor-chart-card">
      <div className="card chart-card">
        {loading && <p>Loading chart data...</p>}
        {error && <p>{error}</p>}
        {sensorData && !loading && !error && (
          <SensorChart dataDict={dataDict} />
        )}
      </div>
    </div>
  );
});

SensorChartCard.propTypes = {
  sensorName: PropTypes.string.isRequired,
  deviceName: PropTypes.string.isRequired,
};

export default SensorChartCard;
