import React, { useState } from 'react';

const AddUserModal = ({ onClose, onSubmit }) => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    email: '',
    full_name: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <dialog open className="modal">
      <article>
        <header>
          <h3>Add New User</h3>
          <button className="close-button" onClick={onClose}>&times;</button>
        </header>
        <form onSubmit={handleSubmit}>
          <label>
            Username
            <input type="text" name="username" value={formData.username} onChange={handleChange} required />
          </label>
          <label>
            Password
            <input type="password" name="password" value={formData.password} onChange={handleChange} required />
          </label>
          <label>
            Email
            <input type="email" name="email" value={formData.email} onChange={handleChange} required />
          </label>
          <label>
            Full Name
            <input type="text" name="full_name" value={formData.full_name} onChange={handleChange} required />
          </label>
          <footer>
            <button type="submit" className="primary">Add User</button>
            <button type="button" className="secondary" onClick={onClose}>Cancel</button>
          </footer>
        </form>
      </article>
    </dialog>
  );
};

export default AddUserModal;
