import React, { useState, useEffect } from 'react';
import { useAxios } from '../../api/AxiosInstance';

const UserManagementModal = ({ organizationName, onClose }) => {
  const [orgUsers, setOrgUsers] = useState([]); // Users in the organization
  const [allUsers, setAllUsers] = useState([]); // All other users
  const [userRoles, setUserRoles] = useState({}); // Track selected role for each user
  const axiosInstance = useAxios();

  // Fetch users when the component mounts
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const orgResponse = await axiosInstance.get(`/organizations/${organizationName}/`);
        setOrgUsers(orgResponse.data.users);

        const allUsersResponse = await axiosInstance.get('/users/');
        const filteredUsers = allUsersResponse.data.filter(
          (user) => !orgResponse.data.users.some((orgUser) => orgUser.username === user.username)
        );
        setAllUsers(filteredUsers);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, [organizationName, axiosInstance]);

  // Add user to organization
  const handleAddUser = async (username) => {
    const role = userRoles[username] || 'Member';
    try {
      await axiosInstance.post(`/organizations/${organizationName}/add_user/`, { username, role });
      setOrgUsers((prev) => [...prev, { username, role }]);
      setAllUsers((prev) => prev.filter((user) => user.username !== username));
      setUserRoles((prevRoles) => {
        const updatedRoles = { ...prevRoles };
        delete updatedRoles[username];
        return updatedRoles;
      });
    } catch (error) {
      console.error('Error adding user:', error);
    }
  };

  // Remove user from organization
  const handleRemoveUser = async (username) => {
    try {
      await axiosInstance.post(`/organizations/${organizationName}/remove_user/`, { username });
      setAllUsers((prev) => [...prev, { username }]);
      setOrgUsers((prev) => prev.filter((user) => user.username !== username));
    } catch (error) {
      console.error('Error removing user:', error);
    }
  };

  // Handle role selection change
  const handleRoleChange = (username, role) => {
    setUserRoles((prevRoles) => ({
      ...prevRoles,
      [username]: role,
    }));
  };

  return (
    <dialog open className="modal" style={{ maxWidth: '600px' }}>
      <article>
        <header>
          <h2>Manage Users for {organizationName}</h2>
          <button aria-label="Close" className="secondary" onClick={onClose}>&times;</button>
        </header>

        <h3>Users in Organization</h3>
        <ul role="list" className="user-list">
          {orgUsers.map((user) => (
            <li key={user.username} className="user-item">
              <span>{user.username} - {user.role}</span>
              <button className="contrast" onClick={() => handleRemoveUser(user.username)}>
                Remove
              </button>
            </li>
          ))}
        </ul>

        <h3>All Other Users</h3>
        <ul role="list" className="user-list">
          {allUsers.map((user) => (
            <li key={user.username} className="user-item">
              <span>{user.username} - </span>
              <div className="user-action">
                <select
                  value={userRoles[user.username] || 'Member'}
                  onChange={(e) => handleRoleChange(user.username, e.target.value)}
                >
                  <option value="Member">Member</option>
                  <option value="Manager">Manager</option>
                </select>
                <button className="secondary" onClick={() => handleAddUser(user.username)}>
                  Add
                </button>
              </div>
            </li>
          ))}
        </ul>

        <footer>
          <button type="button" className="secondary" onClick={onClose}>
            Close
          </button>
        </footer>
      </article>
    </dialog>
  );
};

export default UserManagementModal;
