import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider, AuthContext } from './context/AuthContext';

// Import pages
import Login from './pages/Login/Login';
import Register from './pages/Register/Register';
import Devices from './pages/Devices/Devices';
import Device from './pages/Device/Device';
import Organizations from './pages/Organizations/Organizations';
import Users from './pages/Users/Users';
import DeviceSettings from './pages/DeviceSettings/DeviceSettings';
import AdminTools from './pages/AdminTools/AdminTools';

// Import components
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';

import './App.css';

// Create a layout component that conditionally renders the sidebar
const Layout = ({ children }) => {
  const { user } = useContext(AuthContext);

  return (
    <div className="app-layout">
      <Header />
      <div className="main-content">
        {user && <Sidebar />}
        <div className={`content-wrapper ${!user ? 'no-sidebar' : ''}`}>
          {children}
        </div>
      </div>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <Layout>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route
              path="/devices"
              element={
                <PrivateRoute>
                  <Devices />
                </PrivateRoute>
              }
            />
            <Route
              path="/device/:deviceName"
              element={
                <PrivateRoute>
                  <Device />
                </PrivateRoute>
              }
            />
            <Route
              path="/device/:deviceName/settings"
              element={
                <PrivateRoute>
                  <DeviceSettings />
                </PrivateRoute>
              }
            />
            <Route
              path="/organizations"
              element={
                <PrivateRoute>
                  <Organizations />
                </PrivateRoute>
              }
            />
            <Route
              path="/users"
              element={
                <PrivateRoute>
                  <Users />
                </PrivateRoute>
              }
            />
            <Route
              path="/admin-tools"
              element={
                <PrivateRoute>
                  <AdminTools />
                </PrivateRoute>
              }
            />
          </Routes>
        </Layout>
      </AuthProvider>
    </Router>
  );
};

export default App;