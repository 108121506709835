import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './DeviceList.css';
import { useDeviceApi } from '../../api/device';

const DeviceList = ({ devices }) => {
  const [connectedDevicesMap, setConnectedDevicesMap] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const deviceApi = useDeviceApi();

  useEffect(() => {
    let isMounted = true;

    const fetchAllConnectedDevices = async () => {
      try {
        const map = {};
        const gatewayDevices = devices.filter(device => device.device_type === 'GATEWAY_V1');
        
        if (gatewayDevices.length === 0) {
          if (isMounted) {
            setIsLoading(false);
          }
          return;
        }

        const results = await Promise.all(
          gatewayDevices.map(device => 
            deviceApi.getConnectedDevices(device.device_name)
              .catch(error => {
                console.error(`Error fetching connected devices for ${device.device_name}:`, error);
                return [];
              })
          )
        );

        if (isMounted) {
          gatewayDevices.forEach((device, index) => {
            map[device.device_name] = results[index];
          });
          setConnectedDevicesMap(map);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchAllConnectedDevices();

    return () => {
      isMounted = false;
    };
  }, [devices]);

  // Sort devices: connected devices first, then by name
  const sortedDevices = [...devices].sort((a, b) => {
    if (a.connected && !b.connected) return -1;
    if (!a.connected && b.connected) return 1;
    return a.device_name.localeCompare(b.device_name);
  });

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Loading device information...</p>
      </div>
    );
  }

  return (
    <div className="device-list">
      {sortedDevices.map((device) => {
        // Check if any flags are active
        const hasActiveFlag = device.active_flags && device.active_flags.length > 0;
        const connected = device.connected;
        const connectedDevices = connectedDevicesMap[device.device_name] || [];

        return (
          <div
            key={device.device_name}
            className={`device-card ${hasActiveFlag ? 'highlight-active' : ''} ${!device.connected ? 'device-card--pale' : ''}`}
          >
            <h3>
              <Link to={`/device/${device.device_name}`} className="device-link">
                {device.device_display_name} - {device.device_type}
              </Link>
            </h3>
            <p>{device.connected ? 'Connected' : 'Not Connected'}</p>
            <p>Last Seen: {moment.utc(device.last_online).fromNow()}</p>

            {/* Devices Section for Gateway */}
            {device.device_type === "GATEWAY_V1" && (
              <div className="device-section">
                <h4>Connected Devices:</h4>
                {connectedDevices.length > 0 ? (
                  <ul className="connected-devices-list">
                    {connectedDevices.map((connectedDevice) => (
                      <li key={connectedDevice.name}>
                        {connectedDevice.display_name} ({connectedDevice.name})
                        {connectedDevice.device_type === 'controller' && connectedDevice.ppm_controller_state && (
                          <span className="controller-state">
                            - CLO2 PPM: {connectedDevice.ppm_controller_state.clo2_ppm_sensor}, 
                            pH: {connectedDevice.ppm_controller_state.ph_sensor}
                          </span>
                        )}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No connected devices.</p>
                )}
              </div>
            )}

            {/* Sensors Section - Only show for non-gateway devices */}
            {device.device_type !== "GATEWAY_V1" && device.sensors && device.sensors.length > 0 && (
              <div className="device-section">
                <h4>Sensors:</h4>
                <div className="sensor-list">
                  {device.sensors.map((sensor) => (
                    <div key={sensor.sensor_name} className="sensor-item">
                      {sensor.sensor_name}: {sensor.sensor_value}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Active Flags Section - Only show for non-gateway devices */}
            {device.device_type !== "GATEWAY_V1" && hasActiveFlag && (
              <div className="device-section">
                <h4>Active Flags:</h4>
                <div className="flag-list">
                  {device.active_flags.map((flag) => (
                    <div key={flag.name} className="flag-item">
                      <strong>{flag.name}</strong> - Severity: {flag.severity}
                      {flag.description && <p>Description: {flag.description}</p>}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default DeviceList;
