import React, { useState, useEffect } from 'react';
import { useAxios } from '../../api/AxiosInstance';
import AddUserModal from '../../components/Modals/AddUserModal';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const axiosInstance = useAxios();

  const fetchUsers = async () => {
    try {
      const response = await axiosInstance.get('/users/');
      setUsers(response.data);
    } catch (err) {
      setError('Failed to fetch users');
      console.error('Error fetching users:', err);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleAddUser = async (userData) => {
    try {
      await axiosInstance.post('/register', userData);
      fetchUsers();
      setIsModalOpen(false);
    } catch (err) {
      setError('Failed to add user');
      console.error('Error adding user:', err);
    }
  };

  const handleDeleteUser = async (username) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        await axiosInstance.post(`/users/${username}/delete`);
        fetchUsers();
      } catch (err) {
        setError('Failed to delete user');
        console.error('Error deleting user:', err);
      }
    }
  };

  const handleToggleAdmin = async (username, isAdmin) => {
    try {
      const endpoint = isAdmin ? `/users/${username}/remove_admin` : `/users/${username}/make_admin`;
      await axiosInstance.post(endpoint);
      fetchUsers();
    } catch (err) {
      setError('Failed to update admin status');
      console.error('Error updating admin status:', err);
    }
  };

  return (
    <main className="container">
      <div className="header-container">
        <h2>Users</h2>
        <button 
          className="primary add-user-button"
          onClick={() => setIsModalOpen(true)}
        >
          Add User
        </button>
      </div>

      {error && (
        <article aria-label="Error message" className="error">
          {error}
        </article>
      )}

      <table className="users-table">
        <thead>
          <tr>
            <th>Username</th>
            <th>Email</th>
            <th>Full Name</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.username}>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.full_name}</td>
              <td>
                <button
                  className="secondary"
                  onClick={() => handleToggleAdmin(user.username, user.is_admin)}
                >
                  {user.is_admin ? 'Remove Admin' : 'Make Admin'}
                </button>
                <button
                  className="contrast"
                  onClick={() => handleDeleteUser(user.username)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && (
        <AddUserModal
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleAddUser}
        />
      )}
    </main>
  );
};

export default Users;
