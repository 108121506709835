import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import './Header.css';

const Header = () => {
  const { user, logout } = useContext(AuthContext);

  return (
    <header className="header">
      <div className="header-logo">
        <Link to="/">
          <img
            src="https://aquapproach.com/wp-content/uploads/2022/01/Logo.png"
            alt="Aquapproach Logo"
            className="logo-image"
          />
        </Link>
        <h1 className="header-title">Aquapproach Remote Monitoring</h1>
      </div>
      {user ? (
        <div className="user-info">
          <span>Welcome, {user.full_name}</span>
          <button className="logout-button" onClick={logout}>
            Logout
          </button>
        </div>
      ) : (
        <div className="auth-links">
          <Link to="/">Login</Link>
          <Link to="/register">Register</Link>
        </div>
      )}
    </header>
  );
};

export default Header;
