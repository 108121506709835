import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
import { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// Helper to create a new instance with specific version
export const createVersionedAxios = (baseInstance, version) => {
  const newInstance = axios.create({
    ...baseInstance.defaults,
    baseURL: baseInstance.defaults.baseURL.replace('/api/v1', `/api/${version}`)
  });
  
  // Copy request interceptors
  baseInstance.interceptors.request.handlers.forEach(handler => {
    newInstance.interceptors.request.use(handler.fulfilled, handler.rejected);
  });
  
  // Copy response interceptors
  baseInstance.interceptors.response.handlers.forEach(handler => {
    newInstance.interceptors.response.use(handler.fulfilled, handler.rejected);
  });
  
  return newInstance;
};

export const useAxios = () => {
  const { authTokens, logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const axiosInstance = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authTokens?.access_token}`,
      },
    });

    instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          switch (error.response.status) {
            case 400:
              toast.warning('Bad Request: Please check your input.');
              break;
            case 401:
              logout();
              navigate('/login');
              toast.info('Session expired. Please log in again.');
              break;
            case 403:
              toast.error('Forbidden: You do not have permission to perform this action.');
              break;
            case 404:
              toast.warning('Not Found: The requested resource could not be found.');
              break;
            case 500:
              toast.error('Server Error: Please try again later.');
              break;
            default:
              toast.error('An error occurred. Please try again.');
          }
        } else if (error.request) {
          toast.error('Network error: Please check your internet connection.');
        } else {
          toast.error(`Error: ${error.message}`);
        }
        return Promise.reject(error);
      }
    );

    // Add method to get versioned instance
    instance.getV2 = () => createVersionedAxios(instance, 'v2');

    return instance;
  }, [authTokens, logout, navigate]);

  return axiosInstance;
};
