import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Navigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AdminTools.css';

import DeviceCommandsPanel from '../../components/DeviceCommandsPanel/DeviceCommandsPanel';
import FirmwareManager from '../../components/FirmwareManager/FirmwareManager';
import DeviceFirmwareManager from '../../components/DeviceFirmwareManager/DeviceFirmwareManager';

const AdminTools = () => {
  const { user } = useContext(AuthContext);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [activeTab, setActiveTab] = useState(0);
  
  // Set initial tab based on URL parameters
  useEffect(() => {
    const tabParam = queryParams.get('tab');
    
    if (tabParam === 'commands') {
      setActiveTab(0);
    } else if (tabParam === 'firmware-repo') {
      setActiveTab(1);
    } else if (tabParam === 'firmware-devices') {
      setActiveTab(2);
    }
  }, [location, queryParams]);

  // Redirect non-admin users
  if (!user?.is_admin) {
    return <Navigate to="/devices" replace />;
  }

  return (
    <div className="admin-tools-container">
      <h2>Admin Tools</h2>
      
      <nav>
        <ul>
          <li>
            <button 
              className={activeTab === 0 ? 'secondary' : 'outline'} 
              onClick={() => setActiveTab(0)}
            >
              Device Commands
            </button>
          </li>
          <li>
            <button 
              className={activeTab === 1 ? 'secondary' : 'outline'} 
              onClick={() => setActiveTab(1)}
            >
              Firmware Repository
            </button>
          </li>
          <li>
            <button 
              className={activeTab === 2 ? 'secondary' : 'outline'} 
              onClick={() => setActiveTab(2)}
            >
              Device Firmware
            </button>
          </li>
        </ul>
      </nav>

      {activeTab === 0 && <DeviceCommandsPanel />}
      {activeTab === 1 && <FirmwareManager />}
      {activeTab === 2 && <DeviceFirmwareManager />}
      
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default AdminTools; 