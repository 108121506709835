import { useAxios } from './AxiosInstance';

export const useDeviceApi = () => {
    const axiosInstance = useAxios();
    const v2Api = axiosInstance.getV2();

    return {
        getDeviceInfo: async (deviceMac) => {
            const response = await v2Api.get(`/devices/${deviceMac}/info`);
            return response.data;
        },

        getDeviceSettings: async (deviceMac) => {
            const response = await v2Api.get(`/devices/${deviceMac}/settings`);
            return response.data;
        },

        updateDeviceSettings: async (deviceMac, settings) => {
            const response = await v2Api.post(`/devices/${deviceMac}/settings`, settings);
            return response.data;
        },

        rebootDevice: async (deviceMac) => {
            const response = await v2Api.post(`/devices/${deviceMac}/reboot`);
            return response.data;
        },

        sendDeviceCommand: async (deviceMac, commandType, payload = '') => {
            const response = await v2Api.post(`/devices/${deviceMac}/command`, {
                command_type: commandType,
                payload: payload
            });
            return response.data;
        },

        // Firmware update related functions
        updateDeviceToLatestFirmware: async (deviceMac) => {
            const response = await v2Api.post(`/devices/${deviceMac}/update-firmware`);
            return response.data;
        },

        updateDeviceToSpecificFirmware: async (deviceMac, firmwareId) => {
            const response = await v2Api.post(`/devices/${deviceMac}/update-firmware/${firmwareId}`);
            return response.data;
        },

        getOtaStatus: async (deviceMac) => {
            const response = await v2Api.get(`/devices/${deviceMac}/ota-status`);
            return response.data;
        },

        getConnectedDevices: async (deviceMac) => {
            const response = await v2Api.get(`/devices/${deviceMac}/connected-devices`);
            return response.data;
        }
    };
}; 