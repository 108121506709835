import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';
import SensorChartCard from '../../components/SensorChartCard/SensorChartCard';
import { useAxios } from '../../api/AxiosInstance';
import FlagChart from '../../components/FlagChart/FlagChart';
import './Device.css';
import { FaPen } from 'react-icons/fa';
import { useDeviceApi } from '../../api/device';

const Device = () => {
  const { deviceName } = useParams();
  const [device, setDevice] = useState(null);
  const [deviceDisplayName, setDeviceDisplayName] = useState('');
  const [editingName, setEditingName] = useState(false);
  const [newDisplayName, setNewDisplayName] = useState('');
  const [connectedDevices, setConnectedDevices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const axiosInstance = useAxios();
  const deviceApi = useDeviceApi();
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;

    const fetchDevice = async () => {
      try {
        setIsLoading(true);
        const response = await axiosInstance.get(`/devices/${deviceName}/`);
        if (isMounted) {
          setDevice(response.data);
          setDeviceDisplayName(response.data.device_display_name || '');
          
          // If device is a gateway, fetch connected devices
          if (response.data.device_type === 'GATEWAY_V1') {
            const connectedDevicesData = await deviceApi.getConnectedDevices(deviceName);
            if (isMounted) {
              setConnectedDevices(connectedDevicesData);
            }
          }
        }
      } catch (error) {
        console.error('Error fetching device:', error);
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    fetchDevice();

    return () => {
      isMounted = false;
    };
  }, [deviceName, axiosInstance]);

  const deleteDevice = async () => {
    if (window.confirm(`Are you sure you want to delete device ${deviceDisplayName}?`)) {
      try {
        await axiosInstance.post(`/devices/${deviceName}/delete`);
        alert('Device deleted successfully.');
        navigate('/devices'); // Redirect to devices list after deletion
      } catch (error) {
        console.error('Error deleting device:', error);
        alert('Failed to delete the device.');
      }
    }
  };

  const startEditing = () => {
    setEditingName(true);
    setNewDisplayName(deviceDisplayName);
  };

  const cancelEditing = () => {
    setEditingName(false);
    setNewDisplayName(deviceDisplayName);
  };

  const saveNewName = async () => {
    try {
      await axiosInstance.post(`/devices/${deviceName}/displayname`, { updated_displayname: newDisplayName });
      setDeviceDisplayName(newDisplayName);
      setEditingName(false);
    } catch (error) {
      console.error('Error updating device name:', error);
      alert('Failed to update the device name.');
    }
  };

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Loading device information...</p>
      </div>
    );
  }

  if (!device) {
    return (
      <div className="error-container">
        <p>Failed to load device information.</p>
      </div>
    );
  }

  const lastSeen = device.last_online ? moment.utc(device.last_online).fromNow() : 'Unknown';

  return (
    <section className="content-scrollable">
      <article className="device-card">
        <header>
          <div className="device-header">
            {editingName ? (
              <div className="edit-name-container">
                <input
                  type="text"
                  value={newDisplayName}
                  onChange={(e) => setNewDisplayName(e.target.value)}
                  className="edit-name-input"
                  placeholder="Enter a new name"
                />
                <button onClick={saveNewName} className="save-button">
                  Save
                </button>
                <button onClick={cancelEditing} className="cancel-button">
                  Cancel
                </button>
              </div>
            ) : (
              <h2 className="device-name">
                {deviceDisplayName}
                <button onClick={startEditing} className="rename-button" title="Rename Device">
                  <FaPen />
                </button>
              </h2>
            )}
          </div>
          <div className="device-info">
            <p><strong>Type:</strong> {device.device_type}</p>
            <p><strong>Status:</strong> {device.connected ? 'Connected' : 'Not Connected'}</p>
            <p><strong>Last Seen:</strong> {lastSeen}</p>
          </div>
        </header>
        {device.device_type !== 'GATEWAY_V1' && (
          <>
            <h4>Sensors</h4>
            <div className="sensors-container">
              {device.sensors.map((sensor) => (
                <div key={sensor.sensor_name} className="sensor-item">
                  <article className="sensor-card">
                    <details>
                      <summary>
                        <strong>{sensor.sensor_name} Sensor</strong>, Last value: {sensor.sensor_value}
                      </summary>
                      <SensorChartCard sensorName={sensor.sensor_name} deviceName={device.device_name} />
                    </details>
                  </article>
                </div>
              ))}
            </div>
            <h4>Flags</h4>
            <div className="flags-container">
              {device.flags.map((flag) => (
                <div key={flag.name} className="flag-item">
                  <article className={`flag-card ${flag.active ? 'highlight-active' : ''}`}>
                    <p><strong>{flag.name}</strong> - {flag.active ? 'Active' : 'Inactive'}</p>
                    {flag.description && <p><strong>Description:</strong> {flag.description}</p>}
                    {flag.severity && <p><strong>Severity:</strong> {flag.severity}</p>}
                  </article>
                </div>
              ))}
            </div>
            {device.flags.length > 0 && (
              <article className="sensor-card">
                <details>
                  <summary>
                    <strong>Historic Flags Chart</strong>
                  </summary>
                  <FlagChart deviceName={device.device_name} flags={device.flags} />
                </details>
              </article>
            )}
          </>
        )}
        {device.device_type === 'GATEWAY_V1' && (
          <div className="device-section">
            <h4>Connected Devices</h4>
            {connectedDevices.length > 0 ? (
              <ul className="connected-devices-list">
                {connectedDevices.map((connectedDevice) => (
                  <li key={connectedDevice.name}>
                    {connectedDevice.display_name} ({connectedDevice.name})
                  </li>
                ))}
              </ul>
            ) : (
              <p>No connected devices.</p>
            )}
          </div>
        )}
        <footer className="button-stack">
          <button onClick={() => navigate(`/device/${deviceName}/settings`)} className="settings-button">
            Go to Settings
          </button>
          <button onClick={deleteDevice} className="delete-button">
            Delete Device
          </button>
        </footer>
      </article>
    </section>
  );
};

export default Device;
